import { Duration } from '@js-joda/core';
import { PeriodType } from '@thinkalpha/language-services';
import { getContext, call } from 'redux-saga/effects';
import { getWorkspaceConfigQuery } from 'src/api/queries';
import type {
    AlphaLensWidgetModel,
    TimeSeriesWidgetModel,
    AlphaLensContent,
    StrategyWidgetModel,
    WorkspaceConfig,
    Widget,
    NewsAndEventsWidgetModel,
} from 'src/contracts/workspace';
import { getAlphaLensDefaultWidget } from 'src/lib/user-preference';
import {
    PRIMARY_SERIES_ID,
    VOLUME_SERIES_ID,
    timeSeriesAxisNames,
    timeSeriesPaneNames,
} from 'src/routes/widgets/TimeSeriesWidget/util/constants';
import { defaultBarVolumeFormula } from 'src/routes/widgets/TimeSeriesWidget/util/defaultFormulas';
import { type SagaContext } from 'src/store/types';

export type WidgetDefaultGenerator<T extends Widget> = Generator<any, T, any>;

export function* getDefaultAlphaLensWidget(defaultSymbol?: string): WidgetDefaultGenerator<AlphaLensWidgetModel> {
    let symbol = defaultSymbol;

    if (!symbol) {
        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');
        const baseWidget: Pick<AlphaLensContent, 'symbol' | 'pages'> = yield call(() =>
            queryClient.fetchUserQuery({
                queryKey: ['alpha-lens-widget-model-default'],
                queryFn: getAlphaLensDefaultWidget,
            }),
        );
        symbol = baseWidget.symbol;
    }

    const widget: AlphaLensWidgetModel = {
        type: 'alpha-lens',
        channelId: null,
        keyboardCommands: [],
        header: undefined,
        pages: null,
        symbol: symbol,
    };

    return widget;
}

export function* getDefaultTimeSeriesWidget(defaultSymbol?: string): WidgetDefaultGenerator<TimeSeriesWidgetModel> {
    const widget: TimeSeriesWidgetModel = {
        type: 'time-series',
        barLength: { value: 1, period: PeriodType.day },
        channelId: '',
        interval: {
            start: Duration.ofDays(365),
            end: null,
        },
        session: 'extended',
        plotBandColors: {
            closed: 'rgba(100,100,100,.4)',
            premarket: 'rgba(100,0,0,.4)',
            postmarket: 'rgba(0,0,100,.4)',
            market: '',
        },
        symbol: defaultSymbol ?? 'SPY',
        panes: [
            {
                id: timeSeriesPaneNames[0],
                symbol: null,
                height: 80,
                axes: [
                    {
                        id: timeSeriesAxisNames[0],
                        series: [
                            {
                                id: PRIMARY_SERIES_ID,
                                symbol: undefined,
                                type: 'candle',
                                hidden: false,
                                dataSource: {},
                            },
                        ],
                    },
                ],
            },
            {
                id: timeSeriesPaneNames[1],
                symbol: null,
                height: 20,
                axes: [
                    {
                        id: timeSeriesAxisNames[0],
                        series: [
                            {
                                id: VOLUME_SERIES_ID,
                                symbol: undefined,
                                type: 'column',
                                hidden: false,
                                // TODO AMP: Temporary until formulas are implemented, PLAT-5405
                                dataSource: { value: defaultBarVolumeFormula },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return widget;
}

export function* getDefaultStrategyWidget(
    type: StrategyWidgetModel['type'],
    incomingStrategies: StrategyWidgetModel['strategies'],
    filter?: string,
): WidgetDefaultGenerator<StrategyWidgetModel> {
    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');
    const defaultWorkspaceConfig: WorkspaceConfig = yield call(() =>
        queryClient.fetchUserQuery(getWorkspaceConfigQuery()),
    );

    const widget: StrategyWidgetModel = {
        type,
        channelId: null,
        strategies: incomingStrategies,
        universe: type === 'strategy' ? null : defaultWorkspaceConfig.defaultWatchlistUniverseId,
        columnTemplate: null,
        filter: filter || null,
        scrolling: false,
        displayMode: type === 'strategy' ? 'complete' : 'watchlist',
    };

    return widget;
}

export function* getDefaultNewsAndEventsWidget() {
    const widget: NewsAndEventsWidgetModel = {
        type: 'news-and-events',
        channelId: null,
        strategies: [],
        universe: null,
    };

    return widget;
}
