import type { LDFlagChangeset } from 'launchdarkly-js-sdk-common';
import type { EventChannel } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import { getContext, take, takeEvery, all, put } from 'redux-saga/effects';
import { container } from 'src/ioc/StaticContainer';
import type { SetUserDetailsAction } from 'src/store/actions/auth';
import { setFeatureFlags } from 'src/store/actions/featureFlags';
import type { FeatureFlagSet, SagaContext } from 'src/store/types';

const log = container.get('Logger').getSubLogger({ name: 'ta:sagas:featureFlags' });

type FlagUpdateMessage = { type: 'flagsUpdated'; changeSet: FeatureFlagSet };

const LD_TO_APP_FLAG_MAP: Record<string, keyof FeatureFlagSet> = {
    'constrain-widgets-to-dashboard-bounds': 'constrainWidgetsToDashboardBounds',
    'disable-session-barrier': 'disableSessionBarrier',
    'enable-keyboard-commands': 'enableKeyboardCommands',
    'redesigned-column-template-editor': 'showRedesignedColumnTemplateEditor',
    'context-menu-column-formatting': 'contextMenuColumnFormatting',
    'enable-new-library': 'enableNewLibrary',
    'enable-quick-column-add': 'enableQuickColumnAdd',
    'enable-owner-invitation': 'enableOwnerInvitation',
    'enforce-workspace-id-in-url': 'enforceWorkspaceIdInURL',
    'enable-theme-switching': 'enableThemeSwitching',
    'enable-new-code-editor': 'enableNewCodeEditor',
    'allow-log-tables': 'enableLogTables',
    'enable-trade-widget': 'enableSimpleOrderEntry',
    'system-message': 'systemMessage',
    'news-chatbot': 'newsChatbot',
    'show-alpha-filter-modal': 'showAlphaFilterModal',
};

function* setupLaunchDarkly() {
    const launchDarklyClient: SagaContext['launchDarklyClient'] = yield getContext('launchDarklyClient');

    const channel: EventChannel<FlagUpdateMessage> = eventChannel((emit: (input: FlagUpdateMessage) => void) => {
        const handleChange = (changeSet: LDFlagChangeset) => {
            emit({
                type: 'flagsUpdated',
                changeSet: Object.entries(changeSet).reduce((allFlagsUpdated, currentFlag) => {
                    return { ...allFlagsUpdated, [LD_TO_APP_FLAG_MAP[currentFlag[0]]]: currentFlag[1].current };
                }, {} as FeatureFlagSet),
            });
        };

        launchDarklyClient.waitUntilReady().then(() => {
            launchDarklyClient.on('change', handleChange);

            const initialFlags: FeatureFlagSet = {
                constrainWidgetsToDashboardBounds: launchDarklyClient.variation(
                    'constrain-widgets-to-dashboard-bounds',
                    false,
                ),
                enableOwnerInvitation: launchDarklyClient.variation('enable-owner-invitation', false),
                disableSessionBarrier: launchDarklyClient.variation('disable-session-barrier', false),
                enableKeyboardCommands: launchDarklyClient.variation('enable-keyboard-commands', false),
                showRedesignedColumnTemplateEditor: launchDarklyClient.variation(
                    'redesigned-column-template-editor',
                    false,
                ),
                contextMenuColumnFormatting: launchDarklyClient.variation('context-menu-column-formatting', false),
                enableNewLibrary: launchDarklyClient.variation('enable-new-library', false),
                enableQuickColumnAdd: launchDarklyClient.variation('enable-quick-column-add', false),
                enforceWorkspaceIdInURL: launchDarklyClient.variation('enforce-workspace-id-in-url', false),
                enableThemeSwitching: launchDarklyClient.variation('enable-theme-switching', false),
                enableNewCodeEditor: launchDarklyClient.variation('enable-new-code-editor', false),
                enableLogTables: launchDarklyClient.variation('allow-log-tables', false),
                enableSimpleOrderEntry: launchDarklyClient.variation('enable-trade-widget', false),
                systemMessage: launchDarklyClient.variation('system-message', ''),
                newsChatbot: launchDarklyClient.variation('news-chatbot', false),
                showAlphaFilterModal: launchDarklyClient.variation('show-alpha-filter-modal', false),
            };

            log.debug({ message: 'Initial flags:', initialFlags });

            emit({ type: 'flagsUpdated', changeSet: initialFlags as FeatureFlagSet });
        });

        return () => {
            launchDarklyClient.off('change', handleChange);
        };
    });

    while (true) {
        const ldAction: FlagUpdateMessage = yield take(channel);

        log.debug({ message: 'Flags updated:', changeSet: ldAction.changeSet });

        yield put(setFeatureFlags(ldAction.changeSet));
    }
}

function* onUserSet(action: SetUserDetailsAction) {
    const launchDarklyClient: SagaContext['launchDarklyClient'] = yield getContext('launchDarklyClient');

    const { userDetails } = action;
    if (!userDetails) return;

    log.debug({ message: 'Identifying user:', userDetails });

    yield launchDarklyClient.waitUntilReady();

    launchDarklyClient.identify({
        key: userDetails.id,
        kind: 'user',
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        name: userDetails.email,
        isDeveloper: userDetails.isDeveloper,
        isSuperuser: userDetails.isSuperuser,
        custom: userDetails,
        anonymous: false,
    });
}

export function* featureFlagsSagas() {
    yield all([setupLaunchDarkly(), takeEvery('user-details::set', onUserSet)]);
}
